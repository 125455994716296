export default [
  {
    title: "№",
    name: "index",
    type: "order",
  },
  {
    title: "Маркировка на фабрике",
    name: "factoryMarking",
    component: "text",
    align: "left",
  },
  {
    title: "Производитель",
    name: "manufacturer",
    type: "object",
    align: "left",
  },
  {
    title: "TM",
    name: "trademark",
    type: "object",
    align: "left",
  },
  {
    title: "Модель",
    name: "model",
    type: "object",
    align: "left",
  },
  {
    title: "Артикул",
    name: "article",
    type: "object",
    align: "left",
  },
  {
    title: "Номенклатура",
    name: "nomenclature",
    type: "object",
    align: "left",
  },
  { title: "Материал", name: "material", align: "left" },
  {
    title: "Габаритный размер товара",
    name: "size",
    width: "80",
  },
  {
    title: "Страна происхождения",
    name: "country",
    width: "95",
    type: "object",
  },
  {
    title: "Количество, ШТ",
    name: "quantity",
    component: "decimal",
    align: "left",
  },
  {
    title: "Ед. изм.",
    name: "unit",
    type: "object",
    align: "left",
    width: "50",
  },
  {
    title: "Вес netto",
    name: "weightNetto",
    component: "decimal",
    sortable: false,
    width: "50",
  },
  {
    title: "Вес brutto",
    name: "weightBrutto",
    component: "decimal",
    width: "50",
  },
  {
    title: "Количество мест",
    name: "places",
    component: "decimal",
    align: "left",
    width: "50",
  },
  {
    title: "Объем",
    name: "volume",
    component: "decimal",
  },
  {
    title: "Стоимость",
    name: "cost",
    component: "decimal",
    align: "left",
  },
  {
    title: "Валюта",
    name: "currency",
    align: "left",
    type: "object",
  },
  {
    title: "Сайт товара",
    name: "goodSite",
    type: "link",
    align: "left",
  },
  {
    title: "Таможенный код",
    name: "customsNumber",
    component: "text",
    align: "left",
    width: "80",
  },
  {
    title: "Категория",
    name: "productCategory",
    component: "reference",
    sortable: false,
    align: "left",
  },
  {
    title: "Комментарий",
    name: "notes",
    component: "text",
    align: "left",
  },
  { title: "", name: "menu", type: "menu" },
];