<template>
  <div>
    <v-layout class="mb-2">
      <h2 class="ma-0 pt-1 title font-weight-bold">
        Информация о товаре
      </h2>
    </v-layout>

    <div class="v-table__overflow">
      <div
        class="v-data-table theme--light v-data-table--fixed-header v-data-table--fixed-footer"
      >
        <div
          id="visible-items"
          class="v-data-table__wrapper"
          :style="{
            'overflow-y': 'auto',
            'overflow-x': 'auto'
          }"
        >
          <table class="v-datatable v-table theme--light">
            <thead>
              <slot name="header">
                <tr>
                  <th
                    v-for="header in headers"
                    :key="header.title"
                    :style="{
                      maxWidth: header.width ? `${header.width}px` : 'auto',
                      whiteSpace: header.width ? 'normal' : 'nowrap'
                    }"
                  >
                    <template>
                      <v-layout>
                        <span>{{ header.title }}</span>
                      </v-layout>
                    </template>
                  </th>
                </tr>
              </slot>
            </thead>
            <tbody>
              <template v-for="item in items">
                <GoodsDetailsItemRow
                  :key="item.id"
                  :columns="headers"
                  :value="item"
                />
              </template>
            </tbody>
            <tfoot>
              <slot name="footer">
                <tr>
                  <td
                    v-for="footer in footers"
                    :key="footer.name"
                    class="font-weight-bold px-1"
                  >
                    {{ footer.value }}
                  </td>
                </tr>
              </slot>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GoodsDetailsItemRow from "./GoodsDetailsItemRow.vue";
import goodsDetailsItemsHeaders from "./goods-details-items-headers.js";

export default {
  components: {
    GoodsDetailsItemRow,
  },
  props: [
    "value",
  ],
  data() {
    return {
      headers: goodsDetailsItemsHeaders,
      items: [],
      scale: 3,
    };
  },
  computed: {
    footers() {
      const footers = this.headers.map(h => {
        const item = {
          name: h.name,
          order: 0,
          visible: true,
        };
        if (item.name === "quantity") {
          item.value = this.items.reduce(function(sum, current) {
            const result = sum + +current.quantity;
            return +result.toFixed(2);
          }, 0);
        }
        if (item.name === "cost") {
          item.value = this.items.reduce(function(sum, current) {
            const result = sum + +current.cost;
            return +result.toFixed(2);
          }, 0);
        }
        if (item.name === "weightNetto") {
          item.value = this.items.reduce(function(sum, current) {
            const result = sum + +current.weightNetto;
            return +result.toFixed(3);
          }, 0);
        }
        if (item.name === "weightBrutto") {
          item.value = this.items.reduce(function(sum, current) {
            const result = sum + +current.weightBrutto;
            return +result.toFixed(3);
          }, 0);
        }
        if (item.name === "places") {
          item.value = this.items.reduce(function(sum, current) {
            const result = sum + current.places;
            return +result.toFixed(0);
          }, 0);
        }
        if (item.name === "volume") {
          item.value = this.items.reduce(function(sum, current) {
            const result = sum + +current.volume;
            return +result.toFixed(3);
          }, 0);
        }
        return item;
      });
      return footers;
    },
  },
  watch: {
    value: {
      handler() {
        this.value.forEach(item => (item.index = this.value.indexOf(item) + 1));
        this.items = this.value;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
