<template>
  <tr>
    <template v-for="(column, colIndex) in headers">
      <td :key="colIndex">
        <span
          v-if="column.component === 'decimal'"
        >
          <span>{{
            item[column.name]
          }}</span>
        </span>
        <span
          v-if="column.component === 'reference'"
        >
          <span>{{
            item[column.name] && item[column.name].title
              ? item[column.name].title
              : "_"
          }}</span>
        </span>
        <span
          v-if="column.component === 'text'"
        >
          <span>{{
            item[column.name] || "_"
          }}</span>
        </span>
        <span v-else-if="column.type === 'object'">{{
          item[column.name] ? item[column.name].title : ""
        }}</span>
        <span v-else-if="column.type === 'link'">
          <template v-if="item.goodSite">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <a
                  :href="createUrl(item[column.name])"
                  target="_blank"
                  style="text-decoration: none;"
                  v-on="on"
                >
                  <v-icon small>mdi-language </v-icon>
                </a>
              </template>
              <span>{{ item[column.name] }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <v-icon
              small
              disabled
            >
              mdi-language
            </v-icon>
          </template>
        </span>
        <span v-else-if="!column.component">{{ item[column.name] }}</span>
      </td>
    </template>
  </tr>
</template>
<script>
export default {
  props: ["columns", "value", "order", "disabled"],
  data() {
    return {
      headers: [],
      item: null,
    };
  },
  watch: {
    columns: {
      handler(value) {
        if (value) {
          this.headers = value;
        }
      },
      immediate: true,
    },
    value: {
      handler(value) {
        if (value) {
          this.item = value;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    createUrl(url) {
      const regEx = /http(?=s:\/\/|:\/\/)/;
      if (url && !regEx.test(url)) {
        return `https://${url}`;
      }
      return `${url}`;
    },
  },
};
</script>
