<template>
  <v-layout>
    <app-loader
      title="Загрузка данных Описания товаров"
      :loading="!pageLoaded"
    />
    <v-layout
      v-show="pageLoaded"
      column
      class="no-print"
    >
      <v-flex>
        <v-card
          tile
          class="mt-0 mb-1 ml-0 pa-2 elevation-1"
        >
          <v-layout align-center>
            <v-tooltip bottom>
              <template v-slot:activator="{ on}">
                <v-btn
                  slot="activator"
                  small
                  icon
                  class="ma-0"
                  @click="$router.go(-1)"
                  v-on="on"
                >
                  <v-icon color="grey darken-1">
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Назад</span>
            </v-tooltip>
            <h2 class="px-2 title">
              Описание товаров
            </h2>
          </v-layout>
          <v-layout>
            <span class="primary--text">Поставка №
              <span class="primary--text text-decoration-underline"> {{ number }}</span> </span>
          </v-layout>
        </v-card>
      </v-flex>
      <v-card
        :style="{ height: blockHeight + 'px', 'overflow-y': 'auto' }"
        tile
        class="mt-0 mb-1 ml-0 pa-2 elevation-1"
      >
        <GoodsDetailsItems
          v-model="items"
        />
      </v-card>
    </v-layout>
  </v-layout>
</template>

<script>
import * as shippingsApi from "../../../backend/shippingsApi.js";
import auth from "../../../auth.js";
import GoodsDetailsItems from "./GoodsDetailsItems.vue";

export default {
  components: {
    GoodsDetailsItems,
  },
  props: ["id"],
  data() {
    return {
      items: [],
      loading: false,
      dataRestored: false,
      number: "",
    };
  },
  computed: {
    blockHeight() {
      return window.innerHeight - 230;
    },
    pageLoaded() {
      return !!this.dataRestored;
    },
  },
  created() {
    this.getGoodsDetail();
  },
  methods: {
    async getGoodsDetail() {
      this.loading = true;
      const user = auth.getUser();
      const response = await shippingsApi.getShipping(this.id);
      const res = await shippingsApi.getGoodsDetail(response.data.goodsDetailsId, user.clientId, this.id);
      this.items = res.items;
      this.number = res.shippingNumber;
      this.loading = false;
      this.dataRestored = true;
    },
  },
};
</script>
